<template>
  <div class="main exam flex between">
    <div class="index-top-right">
      <div class="top-ri-tit">网站快速通道</div>
      <div class="top-ri-con">
        <div class="ri-con-nav flex pointer" @click="toUrl('/exam')">
          <img src="@/assets/a-bg2.png" class="nav-bg" />
          <img src="@/assets/a-1.png" class="nav-icon" />
          <div class="con-nav-tit">
            <div class="con-nav-tit1">考试报名</div>
            <div class="con-nav-tit2">Examination registration</div>
          </div>
        </div>
        <div class="ri-con-nav flex pointer" @click="toUrl('/print')">
          <img src="@/assets/a-bg.png" class="nav-bg" />
          <img src="@/assets/a-2.png" class="nav-icon" />
          <div class="con-nav-tit">
            <div class="con-nav-tit1">打印准考证</div>
            <div class="con-nav-tit2">Print admission ticket</div>
          </div>
        </div>
        <div class="ri-con-nav flex pointer" @click="toUrl('/score')">
          <img src="@/assets/a-bg.png" class="nav-bg" />
          <img src="@/assets/a-3.png" class="nav-icon" />
          <div class="con-nav-tit">
            <div class="con-nav-tit1">成绩查询</div>
            <div class="con-nav-tit2">Score query</div>
          </div>
        </div>
      </div>
    </div>
    <div class="exam-right">
      <div class="exam-right-tit">考试报名</div>
      <table v-if="list.length != 0">
        <tr class="bgColor">
          <td>考试名称</td>
          <td>报名入口</td>
          <td style="width: 35%">报名时间</td>
          <!-- <td style="width:15%">考试时间描述</td> -->
        </tr>
        <tr v-for="(item, index) in list" :key="index">
          <td>{{ item.name }}</td>
          <td class="color" @click="toSign(item.id)">点此处去报名</td>
          <td style="font-size: 14px">
            {{ item.apply_start_time }}-{{ item.apply_end_time }}
          </td>
          <!-- <td>{{item.exam_time_desc}}</td> -->
        </tr>
      </table>
      <div class="block" v-if="list.length != 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="zan" v-if="list.length == 0">暂无信息</div>
    </div>
  </div>
</template>
<script>
import { checkToken, examLists } from "@/api/apiData";
export default {
  data() {
    return {
      currentPage4: 4,
      page: 1,
      size: 10,
      list: [],
      total: 1,
    };
  },
  mounted() {
    this.$bus.emit("navS", 4);
    this.getList();
  },
  methods: {
    getList() {
      examLists({ page: this.page, size: this.size }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    toInfo() {
      this.$router.push("/noticeInfo");
    },
    toUrl(u) {
      this.$router.push(u);
    },
    toSign(id) {
      let that = this;
      var token = localStorage.getItem("token");
      checkToken({ token: token }).then((res) => {
        if (res.code == 1) {
          that.$router.push({
            path: "/userInfo",
            query: { id: id },
          });
        } else {
          this.$confirm("您尚未登陆，前往登陆？", "提示", {
            confirmButtonText: "登陆",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              that.$router.push("/signLogin");
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.exam {
  margin-top: 20px;
  .index-top-right {
    width: 295px;
    .top-ri-tit {
      font-size: 14px;
      background: #58beff;
      color: white;
      width: fit-content;
      padding: 5px 10px;
    }
    .top-ri-con {
      background: #e5f5ff;
      padding: 18px 20px;
      .ri-con-nav {
        position: relative;
        width: 255px;
        height: 70px;
        margin-bottom: 20px;
        .nav-bg {
          width: 100%;
          height: 100%;
          position: absolute;
        }
        .nav-icon {
          width: 40px;
          height: 40px;
          position: absolute;
          left: 16px;
          top: 16px;
        }
        .con-nav-tit {
          position: absolute;
          color: white;
          top: 15px;
          left: 72px;
          width: 80%;
          .con-nav-tit1 {
            font-size: 18px;
          }
          .con-nav-tit2 {
            font-size: 10px;
          }
        }
      }
      .ri-con-nav:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }
  .exam-right {
    width: 73%;
    .exam-right-tit {
      color: #0089e1;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    .block {
      text-align: center;
      margin-top: 20px;
    }
    table {
      width: 100%;
      text-align: center;
      font-size: 16px;
      border-left: 1px solid #eeeeee;
      border-collapse: collapse; /*设置单元格的边框合并为1*/
      tr {
        line-height: 3;
      }
      td {
        border-bottom: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
      }
      .bgColor {
        background: #e5f5ff;
        font-size: 20px;
      }
      .color {
        color: #0000ee;
        cursor: pointer;
      }
    }
  }
}
</style>